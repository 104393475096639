import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  deactivateRestoreDiff,
  deletePreviewConfig,
  openPreview,
  saveCampaignConfiguration,
  saveCampaignConfigurationAsDraft,
} from 'actions/campaign';
import { formatDateDistance } from 'reducers/locale';
import {
  hasUnpublishedChangesSelectorFactory,
  hasUnsavedChangesSelector,
  isFaqItemsEmptySelectorFactory,
  publicationDateSelector,
} from 'selectors/campaign';

import {
  SecondaryAccentTabButton,
  TabButton,
} from 'components/ui/button/TabButton';
import {
  ButtonDanger,
  ButtonSecondaryAccent,
} from 'components/ui/button/index';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import { WarningLabel } from 'components/ui/inputs/Label';
import DeleteModal from 'components/ui/modal/DeleteModal';

import { useMemoizedFactorySelector } from 'utils/hooks';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';
import { useLingui } from '@lingui/react';

const DateContainer = styled.div`
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: ${svars.fontSizeSmaller};
  color: ${svars.fontColorLighter};
  font-weight: ${svars.fontWeightLight};
  margin-right: ${svars.spaceNormal};
  & span {
    font-weight: ${svars.fontWeightMedium};
    color: ${svars.fontColorBase};
  }
`;

function SaveControl({ campaignId }) {
  useLingui();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quitModalActivated, setQuitModalActivated] = useState(false);
  const [showDeleteDraftModal, setShowDeleteDraftModal] = useState(false);

  const isFaqItemsEmpty = useMemoizedFactorySelector(
    isFaqItemsEmptySelectorFactory,
    campaignId
  );
  const publicationDate = useSelector(publicationDateSelector(campaignId));
  const campaignHasUnpublishedChanges = useSelector(
    hasUnpublishedChangesSelectorFactory(campaignId)
  );
  const campaignHasUnsavedChanges = useSelector(hasUnsavedChangesSelector);

  const goToCampaignList = useCallback(() => navigate('/campaign'), []);
  const deletePreview = useCallback(
    () => dispatch(deletePreviewConfig(campaignId)),
    [campaignId, dispatch]
  );
  const onPublish = useCallback(async () => {
    dispatch(saveCampaignConfiguration(campaignId));
    dispatch(deactivateRestoreDiff());
  }, [campaignId, dispatch, goToCampaignList]);
  const onPreview = useCallback(async () => {
    dispatch(saveCampaignConfigurationAsDraft(campaignId));
    dispatch(openPreview());
  }, [campaignId, dispatch]);
  const onDeleteDraftAndQuit = useCallback(() => {
    deletePreview();
    setQuitModalActivated(false);
    goToCampaignList();
  }, [dispatch, goToCampaignList, deletePreview]);
  const onSaveDraftAndQuit = useCallback(() => {
    dispatch(saveCampaignConfigurationAsDraft(campaignId));
    setQuitModalActivated(false);
    goToCampaignList();
  }, [campaignId, dispatch, goToCampaignList]);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        margin: `${svars.spaceNormal} 0px`,
      }}
    >
      {publicationDate ? (
        <DateContainer>
          <Trans id="last-published" /> :{' '}
          <span>
            {formatDateDistance(new Date(publicationDate), new Date(), {
              addSuffix: true,
            })}
          </span>
        </DateContainer>
      ) : null}

      {campaignHasUnpublishedChanges ? (
        <WarningLabel color="yellow">
          <Trans id="unpublished-changes" />
          <AnalyticsAwareHoverableIconButtonWithTooltip
            name="trash"
            danger
            help={t({ id: "reset-changes" })}
            style={{
              padding: 0,
              marginLeft: svars.spaceNormal,
              lineHeight: '0.1',
            }}
            onClick={() => setShowDeleteDraftModal(true)}
            mouseEnterDelay={200}
          />
        </WarningLabel>
      ) : null}
      <TabButton
        data-testid="bo-campaign-preview-button"
        style={{ margin: `0 ${svars.spaceNormal}` }}
        onClick={onPreview}
      >
        <Icon name="eye" />
        <Trans render={capitalizedTranslation} id="preview" />
      </TabButton>

      <SecondaryAccentTabButton
        data-testid="bo-campaign-publish-button"
        disabled={
          !(campaignHasUnsavedChanges || campaignHasUnpublishedChanges) ||
          isFaqItemsEmpty
        }
        onClick={onPublish}
      >
        <Icon name="send" />
        <Trans render={capitalizedTranslation} id="publish" />
      </SecondaryAccentTabButton>
      <Modal
        closeIcon
        open={quitModalActivated}
        onClose={() => setQuitModalActivated(false)}
      >
        <Modal.Header>
          <span>
            <Trans id="confirm-quit" />
          </span>
        </Modal.Header>
        <Modal.Content>
          <span>
            <Trans id="unsaved-changes-confirm-quit-customization" />
          </span>
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ButtonSecondaryAccent onClick={() => setQuitModalActivated(false)}>
              <Trans render={capitalizedTranslation} id="cancel" />
            </ButtonSecondaryAccent>
            <div>
              <ButtonSecondaryAccent
                disabled={isFaqItemsEmpty}
                onClick={onSaveDraftAndQuit}
              >
                <Trans id="save-draft-then-quit" />
              </ButtonSecondaryAccent>
              <ButtonDanger onClick={onDeleteDraftAndQuit}>
                <Trans id="delete-changes-then-quit" />
              </ButtonDanger>
            </div>
          </div>
        </Modal.Actions>
      </Modal>
      <DeleteModal
        open={showDeleteDraftModal}
        onClose={() => setShowDeleteDraftModal(false)}
        headerText={t({ id: "deleting-current-draft" })}
        contentText={t({ id: "deleting-current-draft.help-message" })}
        confirmButtonText={t({ id: "delete-changes" })}
        cancelButtonText={t({ id: "cancel" })}
        confirmWithInputValue={null}
        onDelete={deletePreview}
      />
    </div>
  );
}

SaveControl.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

export default SaveControl;
