import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  userLabelFormatterSelector,
  usersIsLoadingSelector,
} from 'selectors/entities';
import { currentUserSelector } from 'selectors/user';
import {
  campaignPermissionsSelectorFactory,
  deleteCampaignPermissions,
  maybeFetchCampaignPermissions,
  updateMemberPermission,
} from 'store/campaign/campaignPermissionsSlice';

import Header from 'components/ui/Header';
import ManagementList from 'components/ui/ManagementList';
import {
  AnalyticsAwareButton,
  ButtonSecondaryAccent,
} from 'components/ui/button/index';
import DeleteModal from 'components/ui/modal/DeleteModal';

import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import CampaignMembersManagementModal from './CampaignMembersManagementModal';
import membersManagementRowFactory from './membersManagementRowFactory';

function MembersManagementTabPage() {
  const { i18n } = useLingui();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const usersAreLoading = useSelector(usersIsLoadingSelector);
  const campaignMembers = useSelector(
    campaignPermissionsSelectorFactory(campaignId)
  );

  useEffect(() => {
    dispatch(maybeFetchCampaignPermissions({ campaignId }));
  });

  const onOpenCreateModal = useCallback(() => {
    setModalOpen(true);
  }, []);
  const onCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const onMemberDeleteValidation = useCallback(
    (member) => () => {
      setDeleteModalOpen(member);
    },
    []
  );

  const onUpdatePermission = useCallback((...params) => {
    dispatch(updateMemberPermission(...params));
  }, []);
  const userLabelFormatter = useSelector(userLabelFormatterSelector);
  const currentUser = useSelector(currentUserSelector);
  const onConfirmDelete = useCallback(() => {
    dispatch(
      deleteCampaignPermissions({
        campaignId,
        permissionId: deleteModalOpen.id,
      })
    ).then(() => {
      setDeleteModalOpen(false);
    });
  }, [deleteModalOpen, campaignId]);

  const [renderItemRow, getTextFromItem] = useMemo(
    () => [
      membersManagementRowFactory(
        onUpdatePermission,
        onMemberDeleteValidation,
        userLabelFormatter,
        currentUser.id
      ),
      (item) => userLabelFormatter(item.user.id),
    ],
    [
      onUpdatePermission,
      onMemberDeleteValidation,
      userLabelFormatter,
      currentUser,
    ]
  );

  return (
    <>
      <Header style={{ marginBottom: svars.spaceSmall }}>
        <Trans id="members-management" />
        <Header.Subheader>
          <Trans id="members-management-description" />
        </Header.Subheader>
      </Header>
      <ManagementList
        testid="bo-members-search-bar"
        loading={usersAreLoading}
        defaultSorted={{
          key: 'create_date',
          ascending: true,
        }}
        renderItemRow={renderItemRow}
        textFilterPlaceholder={capitalize(i18n._(t({ id: 'search-a-member' })))}
        emptyListHeader={<Trans id="campaign-members.no-members" />}
        emptySearchHeader={
          <Trans id="members-list.no-members-found-after-search" />
        }
        items={campaignMembers}
        getTextFromItem={getTextFromItem}
        actions={
          <AnalyticsAwareButton
            gaCategory="Campaign management"
            gaAction="Add member"
            icon="add"
            labelPosition="right"
            inputComponent={ButtonSecondaryAccent}
            onClick={onOpenCreateModal}
            content={<Trans id="add-member-permission" />}
            data-testid="bo-add-member-button"
          />
        }
      />
      {modalOpen ? (
        <CampaignMembersManagementModal
          open={modalOpen}
          onClose={onCloseModal}
          members={campaignMembers}
        />
      ) : null}
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={onConfirmDelete}
        headerText={t({ id: 'members-deletion.confirm-modal-title' })}
        contentText={
          <Trans>
            delete-member.confirm-modal-message-
            <DeleteModal.WarningText>remove-access</DeleteModal.WarningText>
          </Trans>
        }
        confirmWithInputValue={t({ id: 'to-delete-member' })}
      />
    </>
  );
}

MembersManagementTabPage.propTypes = {};

export default MembersManagementTabPage;
