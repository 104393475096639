import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { addDisplayCondition, deleteLogicCondition } from 'actions/survey';
import {
  conditionQuestionItemsSelectorFactory,
  questionAnswerItemsSelector,
  questionAnswerLabelFormatterSelectorFactory,
  questionLabelFormatterSelectorFactory,
} from 'selectors/survey';

import { LightHeader } from 'components/ui/Header';
import StyledSegment from 'components/ui/Segment';
import { TabButton } from 'components/ui/button/TabButton';
import {
  ButtonAccent,
  ButtonTransparentDanger,
} from 'components/ui/button/index';
import { AnalyticsAwareHoverableIconButton } from 'components/ui/icon/HoverableIcon';
import { DropdownTrigger, SelectBox } from 'components/ui/inputs/Dropdown';
import { NFiltersIcon } from 'components/ui/search/FiltersAccordion';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: clip auto;
  flex-grow: 1;
`;

const ConditionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
  width: 100%;
`;

function DisplayLogicEditorModal({
  open,
  condition,
  selectedConditionIndex,
  campaignId,
  questionId,
  onClose,
  conditionQuestionItems,
}) {
  const dispatch = useDispatch();
  const [selectedQuestionId, setSelectedQuestionId] = useState(
    condition?.question?.id
  );
  const [conditionValues, setConditionValues] = useState(
    condition?.values?.map(({ id }) => id) || []
  );

  const questionOptionItems = useSelector(
    questionAnswerItemsSelector(campaignId, true)(selectedQuestionId)
  );
  const questionLabelFormatter = useSelector(
    questionLabelFormatterSelectorFactory(campaignId, true)
  );
  const selectedQuestion = conditionQuestionItems.find(
    (item) => item.value === selectedQuestionId
  );
  const onUpdateLogicConditionQuestion = (e, { value }) => {
    setSelectedQuestionId(value);
  };
  const onUpdateLogicConditionValues = (e, { value }) => {
    setConditionValues(value);
  };
  const onAddLogicCondition = useCallback(() => {
    dispatch(
      addDisplayCondition(
        campaignId,
        questionId,
        selectedQuestionId,
        selectedConditionIndex,
        conditionValues.map((value) => ({ id: value }))
      )
    );
    onClose();
  }, [
    dispatch,
    campaignId,
    questionId,
    selectedQuestionId,
    conditionValues,
    selectedConditionIndex,
  ]);
  return (
    <Modal closeIcon open={open} onClose={onClose}>
      <Modal.Header>
        <Trans id="add-a-condition-to-question" /> : &quot;
        {questionLabelFormatter(questionId)}&quot;
      </Modal.Header>
      <Modal.Content
        style={{
          padding: `${svars.spaceMedium} ${svars.spaceMediumLarge}`,
          minHeight: '50vh',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <p>
          <Trans id="display-question-if" /> &hellip;
        </p>
        <ConditionContainer>
          <SelectBox
            trigger={
              <DropdownTrigger
                value={selectedQuestion}
                placeholder={t({ id: "select-question" })}
              />
            }
            style={{
              margin: `${svars.spaceNormal} ${svars.spaceMedium}`,
              minWidth: '300px',
              flexGrow: 1,
            }}
            value={selectedQuestionId}
            onChange={onUpdateLogicConditionQuestion}
            options={conditionQuestionItems}
            fluid
            selectBoxTestId="bo-combobox-for-select-conditional-question"
          />
        </ConditionContainer>

        <ConditionContainer>
          <Trans id="is" />
          <SelectBox
            placeholder={t({ id: "select-option(s)" })}
            style={{
              margin: `${svars.spaceNormal} ${svars.spaceNormal} ${svars.spaceNormal} ${svars.spaceMedium}`,
              minWidth: '200px',
              flexGrow: 1,
            }}
            fluid
            multiple
            value={conditionValues}
            onChange={onUpdateLogicConditionValues}
            options={questionOptionItems}
            noResultMessage={t({ id: "no-more-options-to-select" })}
            selectBoxTestId="bo-combobox-for-select-conditional-option"
          />
        </ConditionContainer>
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentDanger onClick={onClose} content={t({ id: "cancel" })} />
        <ButtonAccent
          onClick={onAddLogicCondition}
          content={t({ id: "save-condition" })}
          data-testid="bo-save-condition-question-button"
        />
      </Modal.Actions>
    </Modal>
  );
}

DisplayLogicEditorModal.propTypes = {
  campaignId: PropTypes.string.isRequired,
  condition: commonPropTypes.condition.isRequired,
  questionId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  selectedConditionIndex: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  onClose: PropTypes.func.isRequired,
  conditionQuestionItems: PropTypes.arrayOf(commonPropTypes.items).isRequired,
};

DisplayLogicEditorModal.defaultProps = {
  selectedConditionIndex: false,
};

function DisplayLogicEditorWithModal({ campaignId, question }) {
  const dispatch = useDispatch();
  const [displayConditionModal, setDisplayConditionModal] = useState(false);
  const questionLabelFormatter = useSelector(
    questionLabelFormatterSelectorFactory(campaignId, true)
  );
  const answerLabelFormatter = useSelector(
    questionAnswerLabelFormatterSelectorFactory(campaignId)
  );
  const conditionQuestionItems = useSelector(
    conditionQuestionItemsSelectorFactory(
      campaignId,
      question?.id,
      true,
      true,
      question?.conditions?.map(
        ({ question: { id } = {} }) =>
          id !== question.conditions[displayConditionModal]?.question?.id && id
      )
    )
  );
  const openConditionLanguageModal = useCallback(
    (conditionIndex) => () => {
      setDisplayConditionModal(conditionIndex);
    },
    []
  );
  const onDeleteCondition = useCallback(
    (conditionIndex) => () => {
      dispatch(deleteLogicCondition(campaignId, question?.id, conditionIndex));
    },
    [dispatch, campaignId, question?.id]
  );
  return (
    <StyledSegment
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flexShrink: 1,
        // Show at least 1 condition
        minHeight: '140px',
      }}
    >
      <span
        style={{
          display: 'inline-flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <LightHeader>
          <Trans id="display-logic" />
          <LightHeader.Subheader>
            <Trans id="display-question-if" />
          </LightHeader.Subheader>
        </LightHeader>
        <TabButton
          icon="add"
          style={{
            boxSizing: 'initial',
            marginTop: svars.spaceNormal,
            alignSelf: 'center',
          }}
          content={t({ id: "add-condition" })}
          onClick={openConditionLanguageModal(question?.conditions?.length)}
          active={false}
          disabled={!conditionQuestionItems?.length}
          data-testid="bo-campaign-add-condition-icon"
        />
      </span>
      <ScrollableContainer>
        {question.conditions?.map(
          ({ question: conditionQuestion, values }, i) => (
            <div
              key={conditionQuestion.id}
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span style={{ display: 'flex', minWidth: 0 }}>
                {questionLabelFormatter(conditionQuestion?.id)}
                <NFiltersIcon content={values?.length} />
              </span>
              {conditionQuestion?.values?.map(answerLabelFormatter).join(', ')}
              <span style={{ display: 'inline-flex' }}>
                <AnalyticsAwareHoverableIconButton
                  name="edit"
                  onClick={openConditionLanguageModal(i)}
                  tooltipContent={t({ id: "edit" })}
                  tooltipPosition="top center"
                />
                <AnalyticsAwareHoverableIconButton
                  name="delete"
                  danger
                  onClick={onDeleteCondition(i)}
                  tooltipContent={t({ id: "delete" })}
                  tooltipPosition="top center"
                />
              </span>
            </div>
          )
        )}
      </ScrollableContainer>
      {displayConditionModal !== false ? (
        <DisplayLogicEditorModal
          key={displayConditionModal}
          campaignId={campaignId}
          questionId={question?.id}
          condition={question.conditions?.[displayConditionModal]}
          open={displayConditionModal !== false}
          selectedConditionIndex={displayConditionModal}
          onClose={openConditionLanguageModal(false)}
          conditionQuestionItems={conditionQuestionItems}
        />
      ) : null}
    </StyledSegment>
  );
}

DisplayLogicEditorWithModal.propTypes = {
  campaignId: PropTypes.string.isRequired,
  question: commonPropTypes.question.isRequired,
};

export default DisplayLogicEditorWithModal;
