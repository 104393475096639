import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';

import { surveyQuestionsItemsSelectorFactory } from 'selectors/survey';
import {
  channelHiddenQuestionsSelector,
  setChannelHiddenQuestions,
} from 'store/monitor/channelsSlice';

import Accordion, { AccordionToggleTitle } from 'components/ui/Accordion';
import { MediumPaddedSegment } from 'components/ui/Segment';
import CheckboxList from 'components/ui/inputs/checkbox-list/index';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

function ChannelQuestionFilter({ campaignId }) {
  const dispatch = useDispatch();
  const [configureFilteredQuestions, setConfigureFilteredQuestions] =
    useState(false);
  const onSetConfigureFilteredQuestions = useCallback(
    (value) => setConfigureFilteredQuestions(value),
    []
  );

  const questions = useSelector(
    surveyQuestionsItemsSelectorFactory(campaignId)
  );
  const onSetHiddenQuestionIds = useCallback(
    (questionIds) => dispatch(setChannelHiddenQuestions(questionIds)),
    []
  );
  const hiddenQuestionIds = useSelector(channelHiddenQuestionsSelector);
  const isQuestionNotHidden = useCallback(
    (item) => !hiddenQuestionIds.includes(item.value),
    [hiddenQuestionIds]
  );
  const onSelectItem = useCallback(
    ({ value }) =>
      onSetHiddenQuestionIds(hiddenQuestionIds.filter((id) => id !== value)),
    [hiddenQuestionIds]
  );
  const onUnselectItem = useCallback(
    ({ value }) => onSetHiddenQuestionIds([...hiddenQuestionIds, value]),
    [hiddenQuestionIds]
  );
  return (
    <MediumPaddedSegment
      style={{ width: '100%', padding: 0, overflow: 'hidden' }}
    >
      <Accordion key="concept">
        <AccordionToggleTitle
          title={
            <Trans
              render={capitalizedTranslation}
              id="select-questions-to-display"
            />
          }
          help={t({ id: "campaign-channel.select-questions-to-display-help" })}
          active={configureFilteredQuestions}
          onToggle={onSetConfigureFilteredQuestions}
          accordionToggleTestId="bo-create-channels-accordion-toggle"
        />
        <Accordion.Content active={configureFilteredQuestions}>
          <CheckboxList
            style={{ maxHeight: svars.listMaxHeight }}
            items={questions}
            selectedItems={hiddenQuestionIds}
            onSelectItem={onSelectItem}
            onUnselectItem={onUnselectItem}
            isSelectedItem={isQuestionNotHidden}
            placeholder={t({ id: "search-users-for-annotation" })}
            headerStyle={{
              padding: `${svars.spaceSmaller} ${svars.spaceNormal}`,
            }}
          />
        </Accordion.Content>
      </Accordion>
    </MediumPaddedSegment>
  );
}

ChannelQuestionFilter.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

ChannelQuestionFilter.defaultProps = {};

export default ChannelQuestionFilter;
